import React from 'react';
import HeroSection from './../../components/HeroSection';
//import TestimonialsSection from './../../components/TestimonialsSection';
import FeaturesSection from '../../components/CareerFeaturesSection';
import ContentSection from './../../components/ContentSection';
import { useRouter } from './../../util/router.js';
import './styles.scss';

function CareersPage(props) {
  const router = useRouter();

  return (
    <>
      <HeroSection
        color='white'
        size='medium'
        title='What drives you?'
        subtitle='This is where some of the world’s smartest, most passionate people solve problems and create innovative solutions.'
        buttonText='Apply Today'
        image='/assets/imgs/undraw_destinations_fpv7.svg'
        buttonOnClick={() => {
          router.push('/contact');
        }}
      />
      <FeaturesSection
        color='white'
        size='medium'
        title='We bring talented and curious people together to make amazing things happen.'
      />
      {/* <TestimonialsSection
        color='white'
        size='medium'
        title="Here's what people are saying"
        subtitle=''
      /> */}
      <ContentSection
        color='primary'
        size='small'
        title='Devalex is an equal opportunity employer that is committed to inclusion and diversity'
        subtitle='We do not discriminate based upon race, religion, color, national origin, gender (including pregnancy, childbirth, or related medical conditions), sexual orientation, gender identity, gender expression, age, status as a protected veteran, status as an individual with a disability, or other applicable legally protected characteristics. Devalex is committed to working with and providing reasonable accommodations to applicants with physical and mental disabilities.'
      />
    </>
  );
}

export default CareersPage;
