const dev = {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'devalex-api-serverless-prod-attachmentsbucket-14hmgsgsjykok'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://52n6zh0zg0.execute-api.us-east-1.amazonaws.com/prod'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_L0ocrjHfi',
    APP_CLIENT_ID: '155m5nrod5a0122t82fuo7nosb',
    IDENTITY_POOL_ID: 'us-east-1:cf0159ed-4b59-4656-a3b6-19e9f4ac4a9a'
  }
};

const prod = {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'devalex-api-serverless-prod-attachmentsbucket-14hmgsgsjykok'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://52n6zh0zg0.execute-api.us-east-1.amazonaws.com/prod'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_L0ocrjHfi',
    APP_CLIENT_ID: '155m5nrod5a0122t82fuo7nosb',
    IDENTITY_POOL_ID: 'us-east-1:cf0159ed-4b59-4656-a3b6-19e9f4ac4a9a'
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
