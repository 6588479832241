import React from 'react';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import Features from '../Features';
import './styles.scss';

function FeaturesSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className='container'>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Features
          items={[
            {
              title: 'Apply',
              description: 'Submit resume online.',
              image: '/assets/imgs/undraw_organize_resume_utk5.svg'
            },
            {
              title: 'Interview',
              description:
                "Devalex knows the interview process can be daunting, but don't worry. We take a conversational approach for you to get to know us and learn more about you.",
              image: '/assets/imgs/undraw_getting_coffee_wntr.svg'
            },
            {
              title: 'Decide',
              description: 'Join the Devalex family!',
              image: '/assets/imgs/undraw_celebration_0jvk.svg'
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default FeaturesSection;
