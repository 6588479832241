import { API } from 'aws-amplify';

function submit(data) {
  return API.post('contact', '/api/contact', {
    body: {
      name: data.name,
      email: data.email,
      message: data.message
    }
  });
}

export default { submit };
