import React from 'react';
import Navbar from './../../components/Navbar';
import HomePage from './../home';
import AboutPage from './../about';
import FaqPage from './../faq';
import ContactPage from './../contact';
import DashboardPage from './../dashboard';
import CareersPage from './../careers';
import { Switch, Route, Router } from './../../util/router.js';
import Divider from './../../components/Divider';
import Footer from './../../components/Footer';
import analytics from './../../util/analytics.js';
import { ReactComponent as DevalexLogo } from '../../assets/imgs/devalex_logo.svg';
import { ReactComponent as DevalexFooterLogo } from '../../assets/imgs/devalex_footer_logo.svg';
import './styles.scss';

function App(props) {
  return (
    <Router>
      <>
        <Navbar
          color='primary'
          spaced={true}
          logo={<DevalexLogo style={{ height: '40px', width: '156px' }} />}
        />

        <Switch>
          <Route exact path='/' component={HomePage} />

          <Route exact path='/about' component={AboutPage} />

          <Route exact path='/faq' component={FaqPage} />

          <Route exact path='/contact' component={ContactPage} />

          <Route exact path='/dashboard' component={DashboardPage} />

          <Route exact path='/careers' component={CareersPage} />

          <Route
            component={({ location }) => {
              return (
                <div
                  style={{
                    padding: '50px',
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  The page <code>{location.pathname}</code> could not be found.
                </div>
              );
            }}
          />
        </Switch>

        <Divider color='light' />
        <Footer
          color='white'
          size='medium'
          logo={<DevalexFooterLogo />}
          copyright='© Devalex LLC'
        />
      </>
    </Router>
  );
}

export default App;
