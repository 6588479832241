import React, { useState } from 'react';
import NavbarContainer from './../NavbarContainer';
import { Link } from './../../util/router.js';
import './styles.scss';

function Navbar(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <NavbarContainer spaced={props.spaced} color={props.color}>
      <div className='container'>
        <div className='navbar-brand'>
          <div className='navbar-item'>
            <Link to='/'>{props.logo}</Link>
          </div>
          <div
            className={'navbar-burger burger' + (menuOpen ? ' is-active' : '')}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={'navbar-menu' + (menuOpen ? ' is-active' : '')}>
          <div className='navbar-end'>
            {
              <div className='navbar-item has-dropdown is-hoverable'>
                <div className='navbar-link'>Menu</div>
                <div className='navbar-dropdown is-boxed'>
                  <Link className='navbar-item' to='/contact'>
                    Contact Us
                  </Link>
                  <Link className='navbar-item' to='/careers'>
                    Careers
                  </Link>
                  <Link className='navbar-item' to='/about'>
                    About
                  </Link>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
}

export default Navbar;
