import React from 'react';
import './styles.scss';

function Partners(props) {
  return (
    <div className='columns is-vcentered is-centered is-multiline'>
      {props.items.map((item, index) => (
        <div className='column has-text-centered is-centered' key={index}>
          <div className='Partners__logo'>
            <img src={item.image} width={item.width} alt={item.name} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Partners;
