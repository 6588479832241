import React from 'react';
import LandingCarousel from './../../components/Carousel/LandingCarousel';
import PartnerSection from '../../components/PartnerSection';
import FeaturesSection from '../../components/HomeFeaturesSection';
import CtaSection from './../../components/CtaSection';
import { useRouter } from './../../util/router.js';
import './styles.scss';

function HomePage(props) {
  const router = useRouter();

  return (
    <>
      <LandingCarousel
        color='primary'
        size='small'
        title='We Help Customers Advance Innovation'
        subtitle='Lorem Ipsum.'
        buttonText='Contact Us Now'
        buttonOnClick={() => {
          router.push('/contact');
        }}
      />
      <FeaturesSection
        color='white'
        size='medium'
        title='Capabilities'
        subtitle='All the skills you need to move faster and deliver solutions that work'
      />
      <PartnerSection
        color='light'
        size='medium'
        title="You're in good company"
        subtitle='We partner with leading innovators in industry'
      />
      <CtaSection
        color='primary'
        size='medium'
        title='Ready to get started?'
        subtitle=''
        buttonText='Get Started'
        buttonOnClick={() => {
          router.push('/contact');
        }}
      />
    </>
  );
}

export default HomePage;
