import React from 'react';
import DashboardSection from './../../components/DashboardSection';
import { useRouter } from './../../util/router.js';
import './styles.scss';

function DashboardPage(props) {
  const router = useRouter();

  return (
    <DashboardSection
      color='white'
      size='large'
      title='Dashboard'
      subtitle='Dashboard coming soon.'
    />
  );
}

export default DashboardPage;
