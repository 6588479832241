import React from 'react';
import ContentSection from './../../components/ContentSection';
import CtaSection from './../../components/CtaSection';
import { useRouter } from './../../util/router.js';
import './styles.scss';

function AboutPage(props) {
  const router = useRouter();

  return (
    <>
      <ContentSection
        color='white'
        size='small'
        title='We are here to help'
        subtitle='Devalex brings industry knowledge and technical expertise to unlock value and solve your challenges.'
      />
      <div className='container'>
        <section className='articles'>
          <div className='column is-8 is-offset-2'>
            <div className='card article'>
              <div className='card-content'>
                <div className='media'>
                  <div className='media-content has-text-centered'>
                    <h1 className='title article-title'>Our History</h1>
                  </div>
                </div>
                <div className='content article-body'>
                  <p>
                    In 2007, we set out to deliver the best technical services,
                    but we knew that we had to first create a place that was
                    designed to foster that greatness. A place where incredibly
                    talented individuals are empowered to put their best work
                    into the hands of our customers, with very little in their
                    way.
                  </p>
                  <p>Devalex is a U.S. based veteran owned small business:</p>
                  <ul className='menu-list'>
                    <li key={1}>
                      <p>
                        <b>self-funded and employee driven</b> We haven’t ever
                        brought in outside financing. Since day one our focus
                        has been on delivering great results and sharing the
                        reward of those results with those doing the work. Our
                        employees have incredible freedom to shape the company.
                      </p>
                    </li>
                    <li key={2}>
                      <p>
                        <b>more than a defense contractor</b> Devalex is a
                        software company. A platform company. An engineering
                        company. A company full of passionate people who love
                        the products and services we create and deliver.
                      </p>
                    </li>
                    <li key={3}>
                      <p>
                        <b>has a flat hierarchy and organized for impact</b>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <CtaSection
        color='light'
        size='small'
        title='Ready to get started?'
        subtitle=''
        buttonText='Get Started'
        buttonOnClick={() => {
          router.push('/contact');
        }}
      />
    </>
  );
}

export default AboutPage;
