import React from 'react';
import Slider from 'react-slick';
import Section from './../Section';
import SectionHeader from './../SectionHeader';
import SectionButton from './../SectionButton';

const LandingCarousel = props => {
  const settings = {
    dots: false,
    arrows: false,
    fade: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
  };

  return (
    <Section color={props.color} size={props.size}>
      <Slider {...settings}>
        <div>
          <Section color={props.color} size={props.size}>
            <div className="container">
              <SectionHeader
                title="We Help Customers Advance Innovation"
                subtitle="Commercial, Defense, and the Intelligence Community"
                centered={true}
                size={1}
              />
            </div>
          </Section>
        </div>
        <div>
          <Section color={props.color} size={props.size}>
            <div className="container">
              <SectionHeader
                title="We Understand the Importance of Your Mission"
                subtitle="Veteran Owned and Operated"
                centered={true}
                size={1}
              />
            </div>
          </Section>
        </div>
        <div>
          <Section color={props.color} size={props.size}>
            <div className="container">
              <SectionHeader
                title="We Deliver Speed and Agility"
                subtitle="To Meet Your Needs"
                centered={true}
                size={1}
              />
            </div>
          </Section>
        </div>
        <div>
          <Section color={props.color} size={props.size}>
            <div className="container">
              <SectionHeader
                title="Fullstack Development and Engineering"
                subtitle="That Achieves Results"
                centered={true}
                size={1}
              />
            </div>
          </Section>
        </div>
      </Slider>
      <div className="buttons is-centered">
        <SectionButton parentColor={props.color} size="medium" onClick={props.buttonOnClick}>
          {props.buttonText}
        </SectionButton>
      </div>
    </Section>
  );
};

export default LandingCarousel;
