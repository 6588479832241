import React from 'react';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import Features from '../Features';
import './styles.scss';

function FeaturesSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className='container'>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Features
          items={[
            {
              title: 'Fullstack Development',
              description:
                'We have developed, deployed and maintained large scale applications. From backend API creation to frontend user facing interfaces.',
              image: '/assets/imgs/undraw_version_control_9bpv.svg'
            },
            {
              title: 'Cloud',
              description:
                'Our staff has the expertise and industry certifications to help you architect, develop, deploy and sustain your cloud projects.',
              image: '/assets/imgs/undraw_weather_app_i5sm.svg'
            },
            {
              title: 'Data Analytics',
              description:
                'We are experts in pulling disparate data sources together to provide insight that will allow you to make informed decisions.',
              image: '/assets/imgs/undraw_Business_analytics_64el.svg'
            },
            {
              title: 'Automation & DevOps',
              description:
                'Deliver applications at the pace of business with comprehensive DevOps services from Devalex.',
              image: '/assets/imgs/undraw_server_status_5pbv.svg'
            },
            {
              title: 'Data Center Operations',
              description:
                'We provide a wide scope of operational support for the modern data center.',
              image: '/assets/imgs/undraw_building_blocks_n0nc.svg'
            },
            {
              title: 'System Administration',
              description:
                'We will help you configure, deploy and maintain a reliable IT infrastructure.',
              image: '/assets/imgs/undraw_programmer_imem.svg'
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default FeaturesSection;
