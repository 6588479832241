import React from 'react';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import Partners from '../Partners';
import './styles.scss';

import awsLogo from '../../assets/imgs/amazon-associates-logo-png-6.png';
import elasticLogo from '../../assets/imgs/elastic-logo-H-full-color.png';

function PartnerSection(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className='container'>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
      </div>
      <section className='section'>
        <Partners
          items={[
            {
              name: 'AWS',
              image: awsLogo,
              width: '200px'
            },
            {
              name: 'Elastic',
              image: elasticLogo,
              width: '200px'
            }
          ]}
        />
      </section>
    </Section>
  );
}

export default PartnerSection;
